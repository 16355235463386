export type ExperimentParams = {
  [param: string]: readonly any[];
};

export type ExperimentStatic = {
  params: ExperimentParams;
  paths: readonly string[];
};

export const EXPERIMENTS: { [experimentName: string]: ExperimentStatic } = {
  private_premium: {
    params: {
      is_premium: [false, true],
    },
    paths: ['/create', '/create-ai'],
  },
  ai_enabled: {
    params: {
      ai_enabled: [false, true],
    },
    paths: ['/', '/create'],
  },
  // skip_button: {
  //   params: {
  //     skip: [false, true],
  //   },
  //   // A client-side experiment won't need path values
  //   paths: ['/create'],
  // },
} as const;
