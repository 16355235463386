import { ExperimentParams, EXPERIMENTS } from './experiments';

type PathParams = {
  experiments: any;
};

type PathObject = {
  params: PathParams;
};

const pathToExperiments = Object.entries(EXPERIMENTS).reduce(
  (map, [experimentName, experiment]) => {
    experiment.paths.forEach((path) => {
      if (!map[path]) {
        map[path] = [];
      }
      map[path].push(experimentName);
    });
    return map;
  },
  {} as { [path: string]: string[] }
);

export function encodeVariations(path: string, maxGeneratedPaths: number): PathObject[] {
  const experimentsForPath = pathToExperiments[path];

  if (!experimentsForPath || experimentsForPath.length === 0) {
    return [];
  }

  let generatedPaths = 0;
  const paths: PathObject[] = [];

  function generateParams(combo: string[], currentIndex: number, currentPath: string) {
    if (currentIndex === combo.length || generatedPaths >= maxGeneratedPaths) {
      if (currentPath) {
        paths.push({ params: { experiments: currentPath.substring(1) } });
        generatedPaths += 1;
      }
      return;
    }

    const experiment = EXPERIMENTS[combo[currentIndex]];
    const { params } = experiment;
    const paramNames = Object.keys(params);

    for (let i = 0; i < paramNames.length && generatedPaths < maxGeneratedPaths; i += 1) {
      const paramName = paramNames[i];
      for (let j = 0; j < params[paramName].length && generatedPaths < maxGeneratedPaths; j += 1) {
        generateParams(
          combo,
          currentIndex + 1,
          `${currentPath}${i}p${j}v${experimentsForPath.indexOf(combo[currentIndex])}e`
        );
      }
    }
  }

  // Helper function to generate all combinations of experiments
  function getCombinations(inputArray: string[]): string[][] {
    const result: string[][] = [];
    const f = (prefix: string[], remainingArray: string[]) => {
      for (let i = 0; i < remainingArray.length; i += 1) {
        result.push([...prefix, remainingArray[i]]);
        f([...prefix, remainingArray[i]], remainingArray.slice(i + 1));
      }
    };
    f([], inputArray);
    return result;
  }

  const allCombinations = getCombinations(experimentsForPath);
  allCombinations.forEach((combo) => generateParams(combo, 0, `${path}_`));

  console.log('PATHS IS', paths);
  return paths;
}

export function decodeVariations(encodedRoute: string): ExperimentParams[] | null {
  // Split the encoded route into the base page name and the experiment data
  const [basePath, experimentData] = encodedRoute.split('_');

  console.log('THEIAHDAWD', basePath, experimentData, encodedRoute);
  // Get only the experiments that match the given basePath
  const matchingExperiments = Object.keys(EXPERIMENTS).filter((experimentName) =>
    EXPERIMENTS[experimentName].paths.includes(`/${basePath}`)
  );
  // Split the experiment data into parts based on the encoded format
  const parts = experimentData.match(/(\dp\dv\de)/g);

  if (!parts || parts.length === 0) {
    return null;
  }

  const experimentsParams: ExperimentParams[] = [];

  parts.forEach((part) => {
    const paramIndex = parseInt(part.charAt(0), 10);
    const valueIndex = parseInt(part.charAt(2), 10);
    const experimentIndex = parseInt(part.charAt(4), 10);

    // If the experiment index doesn't match any of the experiments for the basePath, skip this part
    if (experimentIndex >= matchingExperiments.length) {
      return;
    }

    const experimentName = matchingExperiments[experimentIndex];
    const experiment = EXPERIMENTS[experimentName];

    const paramName = Object.keys(experiment.params)[paramIndex];
    if (paramName && experiment.params[paramName][valueIndex] !== undefined) {
      const params: { [key: string]: any } = {};
      params[paramName] = experiment.params[paramName][valueIndex];
      experimentsParams.push(params);
    }
  });

  return experimentsParams.length ? experimentsParams : null;
}
