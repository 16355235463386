// Your encoding implementation
import { encodeVariations, decodeVariations } from './encoders';

export function experimentGetStaticPaths(path: string, maxGeneratedPaths = 100) {
  return () => {
    console.log('CURRENT PATH IS', path);
    const paths = encodeVariations(path, maxGeneratedPaths);
    console.log('PATHS ARE', paths);
    return {
      paths,
      fallback: 'blocking',
    };
  };
}

export function experimentGetStaticProps(pageGetStaticProps: any) {
  return async (context: any) => {
    console.log('COOL', context);
    const { props: pageProps, revalidate } = await pageGetStaticProps(context);
    let encodedRoute = context.params?.experiments;

    if (encodedRoute == null && context.params?.slug && context.params?.slug.length) {
      encodedRoute = context.params?.slug[0];
    }
    // Read from URL or use default values
    const experimentsArray = encodedRoute ? decodeVariations(encodedRoute) : null;
    const experiments = experimentsArray
      ? experimentsArray?.reduce((acc, curr) => ({ ...acc, ...curr }), {})
      : null;
    return {
      props: {
        ...pageProps,
        experiments,
      },
      revalidate,
    };
  };
}
