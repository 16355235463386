import { createClient } from '@supabase/supabase-js';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import CryptoJS from 'crypto-js';
import dynamic from 'next/dynamic'
import { supabaseAnonClient } from '../src/Utils';
import { Database } from '../types/supabase';
import { experimentGetStaticPaths, experimentGetStaticProps } from '../experiments/data-fetchers';

const HomeScreen = dynamic(() => import('../components/HomeScreen'))
const TierListPage = dynamic(() => import('../components/TierListPage'))

const Page = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  if (props.isHome) {
    return <HomeScreen {...props} />
  }
  return <TierListPage {...props} />
};

export const getStaticPaths = () => {
  try {
    console.log('Here');
    const pathsData = experimentGetStaticPaths('/')();

    const newPaths = pathsData.paths;
    const finalPaths = newPaths.map((pathObj) => ({
      params: { slug: [pathObj.params.experiments] },
    }));

    return {
      paths: finalPaths,
      fallback: pathsData.fallback,
    };
  } catch (error) {
    console.log('Ayo shit blundered');
  }
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export const homePageGetStaticProps = experimentGetStaticProps(async () => {
  const categories = await supabaseAnonClient.from('categories').select('*');
  return {
    props: {
      categories: (categories.data ?? []).map((category) => category),
    },
  };
});

async function fetchTierListData(supabaseClient: any, tierListId: number) {
  const { data, error } = await supabaseClient
    .from('tier_lists')
    .select('*')
    .eq('id', tierListId)
    .single();
  if (error) throw new Error('Page not found');
  return data;
}

async function fetchSaveData(supabaseClient: any, saveId: string) {
  const { data, error } = await supabaseClient
    .from('tier_lists_save')
    .select('*')
    .eq('save_id', saveId)
    .single();
  if (error) throw new Error('Page not found');
  return data;
}

// ... other imports ...

// Define the actions and their requirements
const actionMap: Record<string, { requiresId: boolean }> = {
  list: { requiresId: true },
  quiz: { requiresId: false },
  'no-cache': { requiresId: false },
  // Add other actions as needed
};
interface ActionProps {
  saveData?: any; // Replace 'any' with the actual type if known
  notFound?: boolean;
  isQuiz?: boolean;
}

async function handleAction(
  supabaseClient: any,
  action: string,
  id?: string,
  password?: string
): Promise<ActionProps> {
  const actionConfig = actionMap[action];
  if (!actionConfig) return { notFound: true };

  if (actionConfig.requiresId && !id) return { notFound: true };

  // Handle specific actions
  switch (action) {
    case 'list':
      // eslint-disable-next-line no-case-declarations
      const saveDataResponse = await fetchSaveData(supabaseClient, id!);
      return {
        saveData:
          password && saveDataResponse
            ? CryptoJS.AES.encrypt(JSON.stringify(saveDataResponse), password).toString()
            : saveDataResponse,
      };
    case 'quiz':
      // Fetch quiz data or handle the quiz logic here
      // For example:
      return { isQuiz: true };
      break;
    // Handle other actions as needed
  }

  return {};
}

export async function tierListGetStaticProps({ params }: { params: { slug: string[] } }) {
  try {
    const [tierId, slug, action, actionId] = params.slug;
    const tierListId = Number(tierId);

    if (Number.isNaN(tierListId)) return { notFound: true };

    const supabaseMasterClient = createClient(
      process.env.NEXT_PUBLIC_SUPABASE_URL ?? '',
      process.env.SUPABASE_SERVICE_ROLE_KEY ?? ''
    );

    const tierListData = await fetchTierListData(supabaseMasterClient, tierListId);

    const actionProps = action
      ? await handleAction(supabaseMasterClient, action, actionId, tierListData.password)
      : {};
    if (actionProps.notFound) return { notFound: true };

    const isEncrypted = tierListData.visibility === 'private' && tierListData.password;

    if (slug !== tierListData.slug && !isEncrypted) {
      return {
        redirect: {
          destination: `/${tierListId}/${tierListData.slug}`,
          permanent: true,
        },
      };
    }

    const revalidate = action === 'no-cache' ? 1 : false;

    return {
      props: {
        tierListData: isEncrypted
          ? CryptoJS.AES.encrypt(JSON.stringify(tierListData), tierListData.password).toString()
          : tierListData,
        encrypted: isEncrypted,
        ...actionProps,
      },
      revalidate,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const staticPaths = getStaticPaths();

  const { slug }: { slug: string[] } = context.params as any;

  let isHome = false;

  const experimentPaths = staticPaths.paths.map((data) => data.params.slug[0]);

  if (slug.length === 0 || slug[0] === '_' || experimentPaths.includes(slug[0])) {
    isHome = true;
  }

  const props = isHome
    ? await homePageGetStaticProps(context)
    : await tierListGetStaticProps(context as any);
  console.log(props);
  return { ...props, props: { ...props.props, isHome } };
};

export default Page;
